import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Container, Box, Chip, Paper, Avatar } from "@mui/material";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, navigate } from "gatsby";
import Desktop from "../../components/desktop/Desktop";
import Grade from "../../components/builds/Grade";
import { toUsdString } from "../../utils";
import { PersonalVideoOutlined, PaidOutlined, ShareOutlined } from "@mui/icons-material";


const page_styles = {
  paddingTop: 8,
  backgroundColor: "#39B54A",
  minHeight: '70vh'
};

const Landing = () => {
  const [randomNumbers, setRandomNumbers] = useState<number[]>([]);

  useEffect(() => {

    function gen_random_numbers(): number[] {
      const appraised = Math.floor(Math.random() * (2000 - 200)) + 200;
      const newPrice = Math.floor(appraised * (1.2 * (Math.random() + 1)));
      const grades = Array.from({ length: 4 }, () =>
        Math.floor(Math.random() * 100)
      );
      return [appraised, newPrice, ...grades];
    }
    // initial
    setRandomNumbers(gen_random_numbers());

    const interval = setInterval(() => {
      setRandomNumbers(gen_random_numbers());
    }, 10000);

    return () => clearInterval(interval);
  }, []);
  return (
    <Header_Layout fullWidth={true}>
      <Container maxWidth={false} sx={page_styles}>
        <Grid container spacing={4} alignItems="flex-end" sx={{ p: 0 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Thinking about selling your desktop?
            </Typography>
            <Typography sx={{ color: "#fff" }} align="center">
              We help our users sell, upgrade, and/or maintain your PC parts.
            </Typography>
            <Grid
              container
              mt={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item md={2} sm={0}></Grid>
                  <Grid
                    item
                    xs={12}
                    direction="column"
                    mb={4}
                    alignItems="center"
                    justifyContent={"center"}
                    display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
                  >
                    <Desktop
                      is_static={false}
                      case_enabled={true}
                      cpu_enabled={true}
                      cpu_cooler_enabled={true}
                      gpu_enabled={true}
                      motherboard_enabled={true}
                      operating_system_enabled={true}
                      psu_enabled={true}
                      ram_enabled={true}
                      storage_enabled={true}
                      case_fan_enabled={true}
                      scale_percentage={50}
                    />
                    <Box display={'inline'}>

                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12} sx={{ textAlign: 'center', m: 2 }}>
                          <Typography
                            variant={"h6"}
                            sx={{
                              color: 'black',
                            }}
                          >
                            The BitLot price:
                          </Typography>
                          <Chip color={'primary'}
                            sx={{
                              fontSize: 32,
                              pt: 1,
                              mt: 1,
                              pb: 1,
                              mb: 1,
                            }}
                            size={'medium'}
                            label={toUsdString(`$${randomNumbers[0]}`)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Performance" score={randomNumbers[2]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Cohesion" score={randomNumbers[3]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Longevity" score={randomNumbers[4]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Budget" score={randomNumbers[5]} />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    mb={4}
                    display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={8} mb={2} display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}>
                            <Desktop
                              is_static={false}
                              case_enabled={true}
                              cpu_enabled={true}
                              cpu_cooler_enabled={true}
                              gpu_enabled={true}
                              motherboard_enabled={true}
                              operating_system_enabled={true}
                              psu_enabled={true}
                              ram_enabled={true}
                              storage_enabled={true}
                              case_fan_enabled={true}
                              scale_percentage={50}
                            />
                          </Grid>
                          <Grid item xs={4} sm={12} md={12} lg={4} sx={{ textAlign: 'center' }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  variant={"h6"}
                                  sx={{
                                    color: 'black',
                                  }}
                                >
                                  The BitLot price:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Chip color={'primary'}
                                  sx={{
                                    fontSize: 32,
                                    pt: 1,
                                    mt: 1,
                                    pb: 1,
                                    mb: 1,
                                  }}
                                  size={'medium'}
                                  label={toUsdString(`$${randomNumbers[0]}`)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Performance" score={randomNumbers[2]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Cohesion" score={randomNumbers[3]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Longevity" score={randomNumbers[4]} />
                            </Paper>
                          </Grid>
                          <Grid item xs={6} sx={{ minWidth: '8rem' }}>
                            <Paper>
                              <Grade type="Budget" score={randomNumbers[5]} />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{ textAlign: "center", p: 4 }}
                  >
                    <Typography
                      variant="h3"
                      mb={4}
                      sx={{ color: "#fff" }}
                      align="center"
                    >
                      Get your parts graded in 5 minutes.
                    </Typography>
                    <Button
                      size={"medium"}
                      variant="contained"
                      sx={{ mb: 4, color: "primary.main", backgroundColor: "background.default" }}
                      onClick={() => navigate("/create")}
                    >
                      {`Create your build`}
                    </Button>
                    <Typography sx={{ color: "#fff" }} align="center" mb={4}>
                      We grade your build or part based on your personal specs as well as industry-wide trends.
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={0}></Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Good as sales: <Grid item xs={12}>

              <Typography
                variant="h3"
                sx={{ color: "#fff" }}
                align="center"
                m={4}
              >We make it simple to sell your PC. Here's how.
              </Typography>
            </Grid> */}
            <Grid item xs={12}>

              <Typography
                variant="h3"
                sx={{ color: "#fff" }}
                align="center"
                m={4}
              >We make it simple to sell your PC parts. Our 3-step process:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2} mb={'5rem'}>
                <Grid item xs={12} md={4} sx={{ textAlign: 'center', alignItems: 'center', minHeight: '12rem' }}>
                  <Paper elevation={12} sx={{ p: 2, m: 2, height: '100%' }}>
                    <Avatar sx={{
                      backgroundColor: 'primary.main',
                      width: '4rem',
                      height: '4rem',
                      margin: 'auto',
                      marginBottom: '1rem'
                    }} color="primary">
                      <PersonalVideoOutlined />
                    </Avatar>
                    <Typography variant={'h5'} sx={{ mb: '1rem' }}>Create your build and get your grades</Typography>
                    <Typography variant={'body1'}>Create your BitLot build with your part(s) and get graded.</Typography>
                    <Button
                      size={"medium"}
                      variant="contained"
                      sx={{ mt: 2, color: "primary.main", backgroundColor: "background.default" }}
                      onClick={() => navigate("/create")}
                    >
                      {`Get started now!`}
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: 'center', alignItems: 'center', minHeight: '12rem' }}>
                  <Paper elevation={12} sx={{ p: 2, m: 2, height: '100%' }}>
                    <Avatar sx={{
                      backgroundColor: 'primary.main',
                      width: '4rem',
                      height: '4rem',
                      margin: 'auto',
                      marginBottom: '1rem'
                    }} color="primary">
                      <PaidOutlined />
                    </Avatar>
                    <Typography variant={'h5'} sx={{ mb: '1rem' }}>Appraise your build and get our estimate</Typography>
                    <Typography variant={'body1'}>We&apos;ll give you a part-by-part breakdown tailored to your build based on algorithmically-informed expertise.</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: 'center', alignItems: 'center', minHeight: '12rem' }}>
                  <Paper elevation={12} sx={{ p: 2, m: 2, height: '100%' }}>
                    <Avatar sx={{
                      backgroundColor: 'primary.main',
                      width: '4rem',
                      height: '4rem',
                      margin: 'auto',
                      marginBottom: '1rem'
                    }} color="primary">
                      <ShareOutlined />
                    </Avatar>
                    <Typography variant={'h5'} sx={{ mb: '1rem' }}>Share your build page with others and get paid</Typography>
                    <Typography variant={'body1'}>Share your build page with others and get paid.</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      </Container >
    </Header_Layout >
  );
};

// why is this here? TODO
Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Landing;

export const Head: HeadFC = () => <title>BitLot.app</title>;
