
import { CircularProgress, Typography, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface Props {
    type: string
    score?: number
    tooltip_content?: string
}

const Grade: React.FC<Props> = ({ type, score, tooltip_content }) => {

    // TODO custom color
    let color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit' = 'error';
    if (score)
        if (score >= 20 && score < 50) {
            color = 'error';
        } else if (score >= 50 && score < 70) {
            color = 'warning';
        } else if (score >= 70 && score < 90) {
            color = 'success';
        } else if (score >= 90) {
            color = 'primary';
        }

    return (
        <Box sx={{ width: '100%' }}>
            <Tooltip arrow title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip_content}</span>}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress color={color} size={120} variant={'determinate'} value={score ?? 0} sx={{
                        m: 'auto',
                        p: 2,
                        '& .MuiCircularProgress-circle': {
                            transition: 'stroke-dashoffset 2s ease-in-out', // Adjust the duration as needed
                        },
                    }} />
                    <Typography variant={'h3'} position='absolute'>{score ?? '-'}</Typography>
                </Box>
            </Tooltip>
            <Typography sx={{ textAlign: 'center' }} variant={'h6'}>{type}</Typography>
        </Box>
    )
};

export default Grade;